// assets
import { IconUser, IconBuilding, IconPlus } from '@tabler/icons';

// constant
const icons = {
    IconUser,
    IconBuilding,
    IconPlus
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const liabilities = {
    id: 'liabilities',
    title: 'liabilities',
    type: 'group',
    children: [
        {
            id: 'create-liabilities',
            title: 'Add New',
            type: 'item',
            url: '/liabilities/create',
            icon: icons.IconPlus,
            breadcrumbs: false
        },
        {
            id: 'index',
            title: 'All Liabilities',
            type: 'item',
            url: '/liabilities',
            icon: icons.IconUser,
            breadcrumbs: false
        }
    ]
};

export default liabilities;
