// assets
import { IconDashboard, IconChartPie } from '@tabler/icons';

// constant
const icons = { IconDashboard, IconChartPie };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'group',
    children: [
        {
            id: 'default',
            title: 'Dashboard',
            type: 'item',
            url: '/',
            icon: icons.IconDashboard,
            breadcrumbs: false
        },
        {
            id: 'equity',
            title: 'Equity',
            type: 'item',
            url: '/equity',
            icon: icons.IconChartPie,
            breadcrumbs: false
        }
    ]
};

export default dashboard;
