import dashboard from './dashboard';
import liabilities from './liabilities';
import transactions from './transactions';
import reimbursements from './reimbursements';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [dashboard, transactions, liabilities, reimbursements]
};

export default menuItems;
