// assets
import { IconHistory, IconRepeat, IconPlus } from '@tabler/icons';

// constant
const icons = {
    IconHistory,
    IconRepeat,
    IconPlus
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const transactions = {
    id: 'transactions',
    title: 'Transactions',
    type: 'group',
    children: [
        {
            id: 'create-transaction',
            title: 'Add New',
            type: 'item',
            url: '/transactions/create',
            icon: icons.IconPlus,
            breadcrumbs: false
        },
        {
            id: 'recursive',
            title: 'Recursive Bills',
            type: 'item',
            url: '/transactions/recursive',
            icon: icons.IconRepeat,
            breadcrumbs: false
        },
        {
            id: 'all-transactions',
            title: 'All Transactions',
            type: 'item',
            url: '/transactions',
            icon: icons.IconHistory,
            breadcrumbs: false
        }
    ]
};

export default transactions;
