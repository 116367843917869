import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import MainLayout from 'layout/MainLayout';

const CreateLiability = Loadable(lazy(() => import('views/liabilities/CreateLiability')));
const AllLiabilities = Loadable(lazy(() => import('views/liabilities')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const LiabilityRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/liabilities/create',
            element: <CreateLiability />
        },
        {
            path: '/liabilities',
            element: <AllLiabilities />
        }
    ]
};

export default LiabilityRoutes;
