import { onAuthStateChanged } from 'firebase/auth';
import { createContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { auth } from '../firebase';

const initialValue = {
    userInfo: null,
    initializing: true
};

export const AuthContext = createContext(initialValue);

export const AuthContextProvider = ({ children }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const currentRouteLogin = location.pathname == '/login';

    const [initializing, setInitializing] = useState(true);
    const [userInfo, setUserInfo] = useState(null);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            setUserInfo(user || null);
            setInitializing(false);

            if (!currentRouteLogin && user == null) navigate('/login');
            if (currentRouteLogin && user != null) navigate('/');
        });

        return () => unsubscribe();
    }, [auth]);

    const value = useMemo(
        () => ({
            userInfo,
            initializing
        }),
        [userInfo, initializing]
    );

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
