import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import MainLayout from 'layout/MainLayout';

const CreateReimbursement = Loadable(lazy(() => import('views/reimbursements/CreateReimbursement')));
const AllReimbursements = Loadable(lazy(() => import('views/reimbursements')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const ReimbursementRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/reimbursements/create',
            element: <CreateReimbursement />
        },
        {
            path: '/reimbursements/bisma',
            element: <AllReimbursements company="bisma" />
        },
        {
            path: '/reimbursements/safebox',
            element: <AllReimbursements company="safebox" />
        }
    ]
};

export default ReimbursementRoutes;
