import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

const AllTransaction = Loadable(lazy(() => import('views/transactions')));
const CreateTransaction = Loadable(lazy(() => import('views/transactions/CreateTransaction')));
const RecursiveBills = Loadable(lazy(() => import('views/transactions/RecursiveBills')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const TransactionRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/transactions',
            element: <AllTransaction />
        },
        {
            path: '/transactions/create',
            element: <CreateTransaction />
        },
        {
            path: '/transactions/recursive',
            element: <RecursiveBills />
        }
    ]
};

export default TransactionRoutes;
