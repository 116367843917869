// assets
import { IconBuilding, IconPlus } from '@tabler/icons';

// constant
const icons = {
    IconBuilding,
    IconPlus
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const reimbursements = {
    id: 'reimbursements',
    title: 'reimbursements',
    type: 'group',
    children: [
        {
            id: 'create-reimbursements',
            title: 'Add New',
            type: 'item',
            url: '/reimbursements/create',
            icon: icons.IconPlus,
            breadcrumbs: false
        },
        {
            id: 'bisma',
            title: 'Bisma',
            type: 'item',
            url: '/reimbursements/bisma',
            icon: icons.IconBuilding,
            breadcrumbs: false
        },
        {
            id: 'safebox',
            title: 'Safebox',
            type: 'item',
            url: '/reimbursements/safebox',
            icon: icons.IconBuilding,
            breadcrumbs: false
        }
    ]
};

export default reimbursements;
