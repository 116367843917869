import { useRoutes } from 'react-router-dom';

// routes
import MainRoutes from './MainRoutes';
import LiabilityRoutes from './LiabilityRoutes';
import TransactionRoutes from './TransactionRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
import ReimbursementRoutes from './ReimbursementRoutes';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    return useRoutes([MainRoutes, LiabilityRoutes, TransactionRoutes, ReimbursementRoutes, AuthenticationRoutes]);
}
